.SkuGenerator {
  display: grid;

  h3, p {
    width: 75%;
    justify-self: center;
  }

  #generateIdsForm {
    display: grid;
    row-gap: 20px;
    justify-items: center;
  }

  .question {
    display: grid;
    width: 75%;
  }
}