html {
  font-size: 62.5%;
}

body {
  margin: 0;
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;

  background-color: lightgray;
}

.App {
  display: grid;
  justify-items: center;
  font-size: 2rem;

  & > *:not(nav) {
    width: 75vw;
    max-width: 700px;
    margin: 20px;
    padding: 20px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 15px 3px #000000;
    box-shadow: 0px 1px 15px 3px #000000;
    background-color: white;
    justify-content: center;
    align-items: center;
  }
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    color: $lightBlue;
  }
}

input {
  font-family: inherit;
  border: 2px solid $blue;
  border-radius: 2px;
  padding: 5px;
  font-size: .75em;
  transition: 0.25s ease-in-out;

  &:focus {
    outline: none;
    border: 2px solid $lightBlue;
  }
}

button {
  cursor: pointer;
  padding: 10px;
  color: white;
  background-color: $blue;
  border-radius: 2px;
  width: fit-content;
  font-size: .75em;
  transition: 0.25s ease-in-out;

  &:hover {
    background-color: $lightBlue;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $lightBlue;
    cursor: not-allowed;
  }
}

.hidden {
  display: none !important;
}

.centered-text {
  text-align: center;
}

h1, h3 {
  text-align: center;
}