nav {
  background-color: $blue;
  height: 50px;
  width: 100vw;
  display: flex;
  font-size: 2rem;

  .nav-link {
    color: white;
    height: 100%;
    width: fit-content;
    padding: 0 10px;
    display: grid;
    align-items: center;

    &:hover {
      background-color: $lightBlue;
    }
  }

  .nav-link-active {
    background-color: $lightBlue;
  }
}