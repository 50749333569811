.bookmarklet {
  display: grid;
  justify-items: center;
}

.a-button {
  width: fit-content;
  padding: 25px;
  border-radius: 5px;
  background-color: $blue;
  color: white;
  &:hover {
    background-color: $lightBlue;
    color: white;
  }
}